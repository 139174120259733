<template>
  <div>
    <v-card class="mx-auto appContainer">
      <v-app-bar dense>
        <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer">
        </v-app-bar-nav-icon>
        <v-toolbar-title>Curriculum Review</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col cols="3" style="padding-top: 40px;">
          <v-combobox
            :items="curriculumList"
            :value="selectedCurriculum"
            label="Select Curriculum"
            dense
            @change="curriculumChange">
          </v-combobox>
        </v-col>
      </v-app-bar>
      <v-navigation-drawer
        class="tree-view-container"
        v-model="showDrawer"
        relative
        absolute
        bottom
        width="40%"
        temporary>
        <v-treeview
          :items="items"
          :open.sync="openNodes"
          activatable
          transition
          open-all
          return-object
          dense
          @update:active="selectionChange">
          <template v-slot:prepend="{item}">
            <div v-if="item.type === 'FOLDER'" class="treeIcon rootFolder"></div>
            <div v-else class="treeIcon" :class="parseFileType(item.type)"></div>
            <!-- <v-icon color="orange" v-if="!item.file">{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
            <v-icon :color="item.iconColor" v-else>{{ item.file }}</v-icon> -->
          </template>
        </v-treeview>
      </v-navigation-drawer>
      <v-card-text>
        <v-container fluid>
          <v-row no-gutters>
            <v-col>
              <iframe v-if="iFrameSource.length > 0" :src="iFrameSource"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container v-if="false" fluid>
      <v-row no-gutters>
        <v-col cols="4" class="hierarchy-view">
          <v-treeview
            ref="treeView"
            :items="items"
            :open.sync="openNodes"
            activatable
            transition
            open-all
            return-object
            dense
            @update:active="selectionChange">
            <template v-slot:prepend="{item}">
              <div v-if="item.type === 'FOLDER'" class="treeIcon rootFolder"></div>
              <div v-else class="treeIcon" :class="parseFileType(item.type)"></div>
              <!-- <v-icon color="orange" v-if="!item.file">{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
              <v-icon :color="item.iconColor" v-else>{{ item.file }}</v-icon> -->
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="8">
          <iframe v-if="iFrameSource.length > 0" :src="iFrameSource"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import hierarchySvc from '../services/hierarchy.service.js';
import curriculumSvc from '../services/curriculum.service.js';
import fileTypeParser from '../mixins/fileTypeParser.mixin.js';

  export default {
    mixins: [fileTypeParser],
    data() {
      return {
        iFrameSource: '',
        items: [],
        openNodes: [],
        showDrawer: true,
        selectedCurriculum: {
          value: '6A993627-C8A8-4E79-91F1-6A8D71C8EF59',
          text: 'Curriculum for Review',
        },
        curriculumList: [
          {
            value: '6A993627-C8A8-4E79-91F1-6A8D71C8EF59',
            text: 'Curriculum for Review',
          }
        ]
      };
    },
    mounted () {
      this.fetchData();
    },
    computed: {
    },
    methods: {
      fetchData() {
        this.curriculumList = curriculumSvc.getCurriculumForUser('rrastelli@gedu-demo-pcg.com');
        if (this.$route.params.curriculumGuid) {
          const filteredList = this.curriculumList.filter(c => c.value === this.$route.params.curriculumGuid);
          if (filteredList && filteredList.length && filteredList.length === 1) {
            this.selectedCurriculum = filteredList[0];
          }
        }
        this.items = hierarchySvc.getCurriculumHierarchy(this.curriculumGuid);
        this.openNodes = this.getFolderNodes(this.items).flat();
      },
      getFolderNodes(items) {
        const folders = [];
        items.forEach(obj => {
          if (obj.type === 'FOLDER') {
            folders.push(obj);
            if (obj.children && obj.children.length > 0) {
              folders.push(this.getFolderNodes(obj.children));
            }
          }
        });
        return folders.flat();
      },
      curriculumChange() {
        this.showDrawer = true;
        this.iFrameSource = '';
      },
      selectionChange(items) {
        this.iFrameSource = '';
        if (items.length > 0) {
          this.labelClick(items[0]);
        }
        this.showDrawer = false;
      },
      labelClick(item) {
        switch(item.type) {
          case 'GoogleDoc':
          case 'GoogleSheet':
          case 'GoogleSlide':
            //this.iFrameSource = `https://docs.google.com/viewer?srcid=${item.sysId}&pid=explorer&efh=false&a=v&chrome=false&embedded=true`;
            this.iFrameSource = `https://dev.bpm.pcgus.com/embed/form/db/1/element/${item.instanceId}/form`;
            break;
          case 'FOLDER':
            this.iFrameSource = `https://dev.bpm.pcgus.com/Embed/v1/app/7ee47634-56b5-42b7-aaed-f7e2fa62f40a/report/49c83862-8614-411b-9e00-16668c8b2e3d?linkMode=1&theme=ad634790-92a7-4a8a-95ba-29c98efb5566&canChangeView=false&CurriculumGuid=${this.curriculumGuid}&HCode=${item.hcode}`
            break;
          default:
            this.iFrameSource = '';
            break;
        }
      }
    },
  }
</script>

<style>
.v-treeview--dense .v-treeview-node__root {
    min-height: 30px;
    cursor: pointer;
}
</style>

<style scoped>
iframe {
  width: 100%;
  max-height: 700px;
  min-height: 700px;
  overflow: hidden;
}
iframe>>>#wrapper {
  overflow: hidden;
}
.v-card.appContainer {
  min-height: 800px;
  max-height: 800px;
  width: 100%;
}
.container {
  min-height: 700px;
  max-height: 700px;
}
.col.hierarchy-view {
  max-height: 700px;
  min-height: 700px;
  overflow-y: scroll;
}
.col.content-view {
  max-height: 700px;
}
.v-navigation-drawer.tree-view-container {
  max-height: 800px !important;
  /* overflow-y: scroll; */
}
</style>