export default {
  curricula: [
    {
      value: '6A993627-C8A8-4E79-91F1-6A8D71C8EF59',
      text: 'Curriculum for Review',
    },
    {
      value: '083DE114-FBE3-4215-A7F6-FDC567724107',
      text: 'Grade 6',
    },
    {
      value: '3A87D77D-B850-4E36-9289-E57D6C550F3D',
      text: 'Grade 7',
    },
    {
      value: 'BD6EA7ED-B379-463F-975E-D10AB3D02E67',
      text: 'Grade 9',
    },
    {
      value: '312EC004-8BA1-402F-9205-A2C09FE9F426',
      text: 'D3C1',
    },
  ]
}