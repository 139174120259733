export default {
  heirarchyTree: [
    {
      id: 253,
      guid: 'E02792F5-2AE2-49B1-B46C-5D8F94051405',
      level: '1',
      name: 'New Hierarchy',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      children: [
        {
          id: 254,
          guid: 'B4D5FDDB-FF04-4F2F-A18B-2D5CA444DA65',
          level: '2',
          name: 'Science',
          type: 'FOLDER',
          sortOrder: 0,
          url: 'NULL',
          children: [
            {
              id: 255,
              guid: 'F2A059AB-67EA-4788-A59B-AE3EDA9EC139',
              level: '3',
              name: 'Grade K-5',
              type: 'FOLDER',
              sortOrder: 0,
              url: 'NULL',
              children: [
                {
                  id: 256,
                  guid: 'D48E620B-CDC4-47C9-BEDC-21D23F50FFC5',
                  level: '4',
                  name: 'Crayfish',
                  type: 'FOLDER',
                  sortOrder: 2,
                  url: 'NULL',
                  children: [
                    {
                      id: 257,
                      guid: 'F016BD27-6983-40E0-9646-AE1DEB289308',
                      level: '5',
                      name: 'Stages of Life',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      children: [
                        {
                          id: 258,
                          guid: 'CAA942B7-6678-4187-8C87-C5C7678B6A19',
                          level: '6',
                          name: 'Molting',
                          type: 'FOLDER',
                          sortOrder: 0,
                          url: 'NULL',
                          children: [
                            {
                              id: 289,
                              guid: 'CAA942B7-6678-4187-8C87-C5C7678B6A19',
                              level: '6',
                              name: 'Stages of Molting',
                              type: 'GoogleSlide',
                              sortOrder: 0,
                              url: 'https://docs.google.com/presentation/d/1_WcR7v5OQg4w5COt4N9xMJiMhY8ub7Qn3r2KRlufwv4/edit?usp=drivesdk,',
                              sysId: '1_WcR7v5OQg4w5COt4N9xMJiMhY8ub7Qn3r2KRlufwv4',
                            },                        
                          ],
                        },
                      ],
                    },
                    {
                      id: 288,
                      guid: 'D48E620B-CDC4-47C9-BEDC-21D23F50FFC5',
                      level: '4',
                      name: 'Development Tracking',
                      type: 'GoogleSheet',
                      sortOrder: 1,
                      url: 'https://docs.google.com/spreadsheets/d/1RaGYacHSHZVR9kSAfNMIyoq-mgsxoo2ZLXL2w4Sqw-A/edit?usp=drivesdk,',
                      sysId: '1RaGYacHSHZVR9kSAfNMIyoq-mgsxoo2ZLXL2w4Sqw-A',
                    },
                  ],
                },
                {
                  id: 259,
                  guid: 'B0E31CAE-DEA6-4CCB-A042-DDDB81693A38',
                  level: '4',
                  name: 'Butterflies',
                  type: 'FOLDER',
                  sortOrder: 0,
                  url: 'NULL',
                  children: [
                    {
                      id: 260,
                      guid: 'ABC9D5E3-8398-4F92-8C16-A5C4A4A76243',
                      level: '5',
                      name: 'Stages of Life',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      children: [
                        {
                          id: 261,
                          guid: 'F3FBFFD3-4297-4BD3-8736-00D984550130',
                          level: '6',
                          name: 'Pupa',
                          type: 'FOLDER',
                          sortOrder: 0,
                          url: 'NULL,'
                        },
                      ]
                    },
                    {
                      id: 291,
                      guid: 'B0E31CAE-DEA6-4CCB-A042-DDDB81693A38',
                      level: '4',
                      name: 'Introduction',
                      type: 'GoogleDoc',
                      sortOrder: 1,
                      url: 'https://docs.google.com/document/d/17ge8RfpiQueuJ04QjT8Sj6sHbE2-kRTnMaseMstgYSM/edit?usp=drivesdk,',
                      sysId: '17ge8RfpiQueuJ04QjT8Sj6sHbE2-kRTnMaseMstgYSM',
                      instanceId: 2399,
                    },
                  ]
                },
              ]
            },
            {
              id: 290,
              guid: 'F2A059AB-67EA-4788-A59B-AE3EDA9EC139',
              level: '3',
              name: 'Introduction',
              type: 'GoogleDoc',
              sortOrder: 1,
              url: 'https://docs.google.com/document/d/1XuhEip3FqvVNCBcsMgFXQt7qkTQcI8gEsUnsXqGwe2w/edit?usp=drivesdk,',
              sysId: '1XuhEip3FqvVNCBcsMgFXQt7qkTQcI8gEsUnsXqGwe2w',
            },
          ]
        },
        {
          id: 262,
          guid: 'BD5B93F7-7565-4142-82CA-3C6FA76CFB66',
          level: '2',
          name: 'Computer Science',
          type: 'FOLDER',
          sortOrder: 1,
          url: 'NULL',
          children: [
            {
              id: 267,
              guid: 'D65D8A3F-34C1-45D2-AE63-C878FC951E23',
              level: '3',
              name: 'Grade 9-12',
              type: 'FOLDER',
              sortOrder: 0,
              url: 'NULL',
              children: [
                {
                  id: 263,
                  guid: 'EE70BDB6-6B6C-4D61-924C-2CB6D3F2E84F',
                  level: '3',
                  name: 'Multi-Platform Languages',
                  type: 'FOLDER',
                  sortOrder: 1,
                  url: 'NULL'
                },
                {
                  id: 268,
                  guid: 'D3248E32-F796-41FA-A915-235E8F0AF34D',
                  level: '4',
                  name: 'Object Oriented Languages',
                  type: 'FOLDER',
                  sortOrder: 1,
                  url: 'NULL'
                },
                {
                  id: 270,
                  //guid: 'DC475A62-7AC3-4181-A9CD-4500766F826B',
                  guid: 'ef584667-63e1-4b04-a87d-93cd478e6095',
                  level: '4',
                  name: 'Procedural Languages',
                  type: 'FOLDER',
                  sortOrder: 0,
                  url: 'NULL'
                },
              ]
            },
          ]
        },
      ],
    }
  ],
  emptyNode: {
    id: 0,
    guid: '',
    level: '',
    name: 'newNode',
    type: 'FOLDER',
    sortOrder: 0,
    url: '',
    children: [],
  },
  structuredNodes: [
    {
      id: 253,
      guild: 'E02792F5-2AE2-49B1-B46C-5D8F94051405',
      level: '1',
      hcode: 'e02792f5',
      name: 'New Hierarchy',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
        {
          id: 254,
          guild: 'B4D5FDDB-FF04-4F2F-A18B-2D5CA444DA65',
          level: '2',
          hcode: 'b4d5fddb',
          name: 'Science',
          type: 'FOLDER',
          sortOrder: 0,
          url: 'NULL',
          sysId: '0',
          instanceId: '0',
          children: [
            {
              id: 255,
              guild: 'F2A059AB-67EA-4788-A59B-AE3EDA9EC139',
              level: '3',
              hcode: 'f2a059ab',
              name: 'Grade K-5',
              type: 'FOLDER',
              sortOrder: 0,
              url: 'NULL',
              sysId: '0',
              instanceId: '0',
              children: [
                {
                  id: 256,
                  guild: 'D48E620B-CDC4-47C9-BEDC-21D23F50FFC5',
                  level: '4',
                  hcode: 'd48e620b',
                  name: 'Crayfish',
                  type: 'FOLDER',
                  sortOrder: 2,
                  url: 'NULL',
                  sysId: '0',
                  instanceId: '0',
                  children: [
                    {
                      id: 257,
                      guild: 'F016BD27-6983-40E0-9646-AE1DEB289308',
                      level: '5',
                      hcode: 'f016bd27',
                      name: 'Stages of Life',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                        {
                          id: 258,
                          guild: 'CAA942B7-6678-4187-8C87-C5C7678B6A19',
                          level: '6',
                          hcode: 'caa942b7',
                          name: 'Molting',
                          type: 'FOLDER',
                          sortOrder: 0,
                          url: 'NULL',
                          sysId: '0',
                          instanceId: '0',
                          children: [
                            {
                              id: 289,
                              guild: 'CAA942B7-6678-4187-8C87-C5C7678B6A19',
                              level: '6',
                              hcode: 'caa942b7',
                              name: 'Stages of Molting',
                              type: 'GoogleSlide',
                              sortOrder: 0,
                              url: 'https://docs.google.com/presentation/d/1_WcR7v5OQg4w5COt4N9xMJiMhY8ub7Qn3r2KRlufwv4/edit?usp=drivesdk',
                              sysId: '1_WcR7v5OQg4w5COt4N9xMJiMhY8ub7Qn3r2KRlufwv4',
                              instanceId: '2397',
                            },
                          ]
                        },
                      ]
                    },
                    {
                      id: 288,
                      guild: 'D48E620B-CDC4-47C9-BEDC-21D23F50FFC5',
                      level: '4',
                      hcode: 'd48e620b',
                      name: 'Development Tracking',
                      type: 'GoogleSheet',
                      sortOrder: 1,
                      url: 'https://docs.google.com/spreadsheets/d/1RaGYacHSHZVR9kSAfNMIyoq-mgsxoo2ZLXL2w4Sqw-A/edit?usp=drivesdk',
                      sysId: '1RaGYacHSHZVR9kSAfNMIyoq-mgsxoo2ZLXL2w4Sqw-A',
                      instanceId: '2396',
                    },
                  ]
                },
                {
                  id: 259,
                  guild: 'B0E31CAE-DEA6-4CCB-A042-DDDB81693A38',
                  level: '4',
                  hcode: 'b0e31cae',
                  name: 'Butterflies',
                  type: 'FOLDER',
                  sortOrder: 0,
                  url: 'NULL',
                  sysId: '0',
                  instanceId: '0',
                  children: [
                    {
                      id: 260,
                      guild: 'ABC9D5E3-8398-4F92-8C16-A5C4A4A76243',
                      level: '5',
                      hcode: 'abc9d5e3',
                      name: 'Stages of Life',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                        {
                          id: 261,
                          guild: 'F3FBFFD3-4297-4BD3-8736-00D984550130',
                          level: '6',
                          hcode: 'f3fbffd3',
                          name: 'Pupa',
                          type: 'FOLDER',
                          sortOrder: 0,
                          url: 'NULL',
                          sysId: '0',
                          instanceId: '0',
                          children: [
                          ]
                        },
                      ]
                    },
                    {
                      id: 291,
                      guild: 'B0E31CAE-DEA6-4CCB-A042-DDDB81693A38',
                      level: '4',
                      hcode: 'b0e31cae',
                      name: 'Introduction',
                      type: 'GoogleDoc',
                      sortOrder: 1,
                      url: 'https://docs.google.com/document/d/17ge8RfpiQueuJ04QjT8Sj6sHbE2-kRTnMaseMstgYSM/edit?usp=drivesdk',
                      sysId: '17ge8RfpiQueuJ04QjT8Sj6sHbE2-kRTnMaseMstgYSM',
                      instanceId: '2399',
                    },
                  ]
                },
                {
                  id: 290,
                  guild: 'F2A059AB-67EA-4788-A59B-AE3EDA9EC139',
                  level: '3',
                  hcode: 'f2a059ab',
                  name: 'Introduction',
                  type: 'GoogleDoc',
                  sortOrder: 1,
                  url: 'https://docs.google.com/document/d/1XuhEip3FqvVNCBcsMgFXQt7qkTQcI8gEsUnsXqGwe2w/edit?usp=drivesdk',
                  sysId: '1XuhEip3FqvVNCBcsMgFXQt7qkTQcI8gEsUnsXqGwe2w',
                  instanceId: '2398',
                },
              ]
            },
          ]
        },
        {
          id: 262,
          guild: 'BD5B93F7-7565-4142-82CA-3C6FA76CFB66',
          level: '2',
          hcode: 'bd5b93f7',
          name: 'Computer Science',
          type: 'FOLDER',
          sortOrder: 1,
          url: 'NULL',
          sysId: '0',
          instanceId: '0',
          children: [
            {
              id: 267,
              guild: 'D65D8A3F-34C1-45D2-AE63-C878FC951E23',
              level: '3',
              hcode: 'd65d8a3f',
              name: 'Grade 9-12',
              type: 'FOLDER',
              sortOrder: 0,
              url: 'NULL',
              sysId: '0',
              instanceId: '0',
              children: [
                {
                  id: 263,
                  guild: 'EE70BDB6-6B6C-4D61-924C-2CB6D3F2E84F',
                  level: '4',
                  hcode: 'ee70bdb6',
                  name: 'Multi-Platform Languages',
                  type: 'FOLDER',
                  sortOrder: 2,
                  url: 'NULL',
                  sysId: '0',
                  instanceId: '0',
                  children: [
                    {
                      id: 264,
                      guild: 'BBD09844-6742-4F09-B81E-4AAE03F33F71',
                      level: '5',
                      hcode: 'bbd09844',
                      name: 'VB .Net',
                      type: 'FOLDER',
                      sortOrder: 2,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                      ]
                    },
                    {
                      id: 265,
                      guild: 'FA0A02AB-E005-4F28-A89C-C843C929ED4A',
                      level: '5',
                      hcode: 'fa0a02ab',
                      name: 'C#',
                      type: 'FOLDER',
                      sortOrder: 1,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                      ]
                    },
                    {
                      id: 266,
                      guild: 'F7C63986-79FD-482C-B6EF-E31416C4EB09',
                      level: '5',
                      hcode: 'f7c63986',
                      name: 'Java',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                      ]
                    },
                  ]
                },
                {
                  id: 268,
                  guild: 'D3248E32-F796-41FA-A915-235E8F0AF34D',
                  level: '4',
                  hcode: 'd3248e32',
                  name: 'Object Oriented Languages',
                  type: 'FOLDER',
                  sortOrder: 1,
                  url: 'NULL',
                  sysId: '0',
                  instanceId: '0',
                  children: [
                    {
                      id: 269,
                      guild: 'C958FCF9-0910-423F-BB01-77F595BE7495',
                      level: '5',
                      hcode: 'c958fcf9',
                      name: 'C++',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                      ]
                    },
                  ]
                },
                {
                  id: 270,
                  guild: 'DC475A62-7AC3-4181-A9CD-4500766F826B',
                  level: '4',
                  hcode: 'dc475a62',
                  name: 'Procedural Languages',
                  type: 'FOLDER',
                  sortOrder: 0,
                  url: 'NULL',
                  sysId: '0',
                  instanceId: '0',
                  children: [
                    {
                      id: 271,
                      guild: 'EBE375DB-6DCC-4061-B2E7-0C9AE99A930D',
                      level: '5',
                      hcode: 'ebe375db',
                      name: 'Pascal',
                      type: 'FOLDER',
                      sortOrder: 0,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                        {
                          id: 272,
                          guild: 'F9C69ADE-AA89-43B5-85D8-77AEC024CBED',
                          level: '6',
                          hcode: 'f9c69ade',
                          name: 'Constants & Variables',
                          type: 'FOLDER',
                          sortOrder: 0,
                          url: 'NULL',
                          sysId: '0',
                          instanceId: '0',
                          children: [
                            {
                              id: 292,
                              guild: 'F9C69ADE-AA89-43B5-85D8-77AEC024CBED',
                              level: '6',
                              hcode: 'f9c69ade',
                              name: 'Type & Scope',
                              type: 'GoogleDoc',
                              sortOrder: 0,
                              url: 'https://docs.google.com/document/d/1Id1fkwadt6PjCsJRtTODINEBbPIuqF-dJJ9M15jLW8g/edit?usp=drivesdk',
                              sysId: '1Id1fkwadt6PjCsJRtTODINEBbPIuqF-dJJ9M15jLW8g',
                              instanceId: '2400',
                            },
                          ]
                        },
                        {
                          id: 273,
                          guild: 'F193B1F0-D8E9-4AA8-8515-97BDA0EA6D40',
                          level: '6',
                          hcode: 'f193b1f0',
                          name: 'Functions & Procedures',
                          type: 'FOLDER',
                          sortOrder: 1,
                          url: 'NULL',
                          sysId: '0',
                          instanceId: '0',
                          children: [
                            {
                              id: 293,
                              guild: 'F193B1F0-D8E9-4AA8-8515-97BDA0EA6D40',
                              level: '6',
                              hcode: 'f193b1f0',
                              name: 'Return Types',
                              type: 'GoogleDoc',
                              sortOrder: 1,
                              url: 'https://docs.google.com/document/d/1Q4bAdMJggc_los7tEtV8gFFop8DmAwQFfkQuytP_KZA/edit?usp=drivesdk',
                              sysId: '1Q4bAdMJggc_los7tEtV8gFFop8DmAwQFfkQuytP_KZA',
                              instanceId: '2401',
                            },
                            {
                              id: 294,
                              guild: 'F193B1F0-D8E9-4AA8-8515-97BDA0EA6D40',
                              level: '6',
                              hcode: 'f193b1f0',
                              name: 'Decleration',
                              type: 'GoogleDoc',
                              sortOrder: 0,
                              url: 'https://docs.google.com/document/d/1JDaYXNVcB3aPltU84AWN-wc-TcK06-IZ1xANLaiaVcQ/edit?usp=drivesdk',
                              sysId: '1JDaYXNVcB3aPltU84AWN-wc-TcK06-IZ1xANLaiaVcQ',
                              instanceId: '2402',
                            },
                          ]
                        },
                      ]
                    },
                    {
                      id: 274,
                      guild: 'F39A5751-0F91-48DD-810D-752ED5611724',
                      level: '5',
                      hcode: 'f39a5751',
                      name: 'Visual Basic',
                      type: 'FOLDER',
                      sortOrder: 2,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                      ]
                    },
                    {
                      id: 275,
                      guild: 'A8F34CC9-6D4D-4714-B8D7-C3E8037BD244',
                      level: '5',
                      hcode: 'a8f34cc9',
                      name: 'C',
                      type: 'FOLDER',
                      sortOrder: 1,
                      url: 'NULL',
                      sysId: '0',
                      instanceId: '0',
                      children: [
                        {
                          id: 276,
                          guild: 'B419DED8-CA07-4326-9F02-1E3A3A2F0A25',
                          level: '6',
                          hcode: 'b419ded8',
                          name: 'Constants & Variables',
                          type: 'FOLDER',
                          sortOrder: 0,
                          url: 'NULL',
                          sysId: '0',
                          instanceId: '0',
                          children: [
                          ]
                        },
                        {
                          id: 277,
                          guild: 'A0685096-2A83-463A-8D2D-5CBDABC4131E',
                          level: '6',
                          hcode: 'a0685096',
                          name: 'Functions',
                          type: 'FOLDER',
                          sortOrder: 1,
                          url: 'NULL',
                          sysId: '0',
                          instanceId: '0',
                          children: [
                          ]
                        },
                      ]
                    },
                    {
                      id: 295,
                      guild: 'DC475A62-7AC3-4181-A9CD-4500766F826B',
                      level: '4',
                      hcode: 'dc475a62',
                      name: 'Introduction',
                      type: 'GoogleDoc',
                      sortOrder: 3,
                      url: 'https://docs.google.com/document/d/1xS7CTgKG-OD7zzTFxMzySSj9QKzHIFQbjDAkHs4mkoY/edit?usp=drivesdk',
                      sysId: '1xS7CTgKG-OD7zzTFxMzySSj9QKzHIFQbjDAkHs4mkoY',
                      instanceId: '2403',
                    },
                  ]
                },
              ]
            },
          ]
        },
      ]
    },
  ],
  nodes: [
    {
      id: 253,
      guild: 'E02792F5-2AE2-49B1-B46C-5D8F94051405',
      level: '1',
      hcode: 'e02792f5',
      name: 'New Hierarchy',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 254,
      guild: 'B4D5FDDB-FF04-4F2F-A18B-2D5CA444DA65',
      level: '2',
      hcode: 'b4d5fddb',
      name: 'Science',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 255,
      guild: 'F2A059AB-67EA-4788-A59B-AE3EDA9EC139',
      level: '3',
      hcode: 'f2a059ab',
      name: 'Grade K-5',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 256,
      guild: 'D48E620B-CDC4-47C9-BEDC-21D23F50FFC5',
      level: '4',
      hcode: 'd48e620b',
      name: 'Crayfish',
      type: 'FOLDER',
      sortOrder: 2,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 257,
      guild: 'F016BD27-6983-40E0-9646-AE1DEB289308',
      level: '5',
      hcode: 'f016bd27',
      name: 'Stages of Life',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 258,
      guild: 'CAA942B7-6678-4187-8C87-C5C7678B6A19',
      level: '6',
      hcode: 'caa942b7',
      name: 'Molting',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 289,
      guild: 'CAA942B7-6678-4187-8C87-C5C7678B6A19',
      level: '6',
      hcode: 'caa942b7',
      name: 'Stages of Molting',
      type: 'GoogleSlide',
      sortOrder: 0,
      url: 'https://docs.google.com/presentation/d/1_WcR7v5OQg4w5COt4N9xMJiMhY8ub7Qn3r2KRlufwv4/edit?usp=drivesdk',
      sysId: '1_WcR7v5OQg4w5COt4N9xMJiMhY8ub7Qn3r2KRlufwv4',
      instanceId: '2397',
    },
    {
      id: 259,
      guild: 'B0E31CAE-DEA6-4CCB-A042-DDDB81693A38',
      level: '4',
      hcode: 'b0e31cae',
      name: 'Butterflies',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 260,
      guild: 'ABC9D5E3-8398-4F92-8C16-A5C4A4A76243',
      level: '5',
      hcode: 'abc9d5e3',
      name: 'Stages of Life',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 261,
      guild: 'F3FBFFD3-4297-4BD3-8736-00D984550130',
      level: '6',
      hcode: 'f3fbffd3',
      name: 'Pupa',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 288,
      guild: 'D48E620B-CDC4-47C9-BEDC-21D23F50FFC5',
      level: '4',
      hcode: 'd48e620b',
      name: 'Development Tracking',
      type: 'GoogleSheet',
      sortOrder: 1,
      url: 'https://docs.google.com/spreadsheets/d/1RaGYacHSHZVR9kSAfNMIyoq-mgsxoo2ZLXL2w4Sqw-A/edit?usp=drivesdk',
      sysId: '1RaGYacHSHZVR9kSAfNMIyoq-mgsxoo2ZLXL2w4Sqw-A',
      instanceId: '2396',
    },
    {
      id: 291,
      guild: 'B0E31CAE-DEA6-4CCB-A042-DDDB81693A38',
      level: '4',
      hcode: 'b0e31cae',
      name: 'Introduction',
      type: 'GoogleDoc',
      sortOrder: 1,
      url: 'https://docs.google.com/document/d/17ge8RfpiQueuJ04QjT8Sj6sHbE2-kRTnMaseMstgYSM/edit?usp=drivesdk',
      sysId: '17ge8RfpiQueuJ04QjT8Sj6sHbE2-kRTnMaseMstgYSM',
      instanceId: '2399',
    },
    {
      id: 290,
      guild: 'F2A059AB-67EA-4788-A59B-AE3EDA9EC139',
      level: '3',
      hcode: 'f2a059ab',
      name: 'Introduction',
      type: 'GoogleDoc',
      sortOrder: 1,
      url: 'https://docs.google.com/document/d/1XuhEip3FqvVNCBcsMgFXQt7qkTQcI8gEsUnsXqGwe2w/edit?usp=drivesdk',
      sysId: '1XuhEip3FqvVNCBcsMgFXQt7qkTQcI8gEsUnsXqGwe2w',
      instanceId: '2398',
    },
    {
      id: 262,
      guild: 'BD5B93F7-7565-4142-82CA-3C6FA76CFB66',
      level: '2',
      hcode: 'bd5b93f7',
      name: 'Computer Science',
      type: 'FOLDER',
      sortOrder: 1,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 267,
      guild: 'D65D8A3F-34C1-45D2-AE63-C878FC951E23',
      level: '3',
      hcode: 'd65d8a3f',
      name: 'Grade 9-12',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 263,
      guild: 'EE70BDB6-6B6C-4D61-924C-2CB6D3F2E84F',
      level: '4',
      hcode: 'ee70bdb6',
      name: 'Multi-Platform Languages',
      type: 'FOLDER',
      sortOrder: 2,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 264,
      guild: 'BBD09844-6742-4F09-B81E-4AAE03F33F71',
      level: '5',
      hcode: 'bbd09844',
      name: 'VB .Net',
      type: 'FOLDER',
      sortOrder: 2,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 265,
      guild: 'FA0A02AB-E005-4F28-A89C-C843C929ED4A',
      level: '5',
      hcode: 'fa0a02ab',
      name: 'C#',
      type: 'FOLDER',
      sortOrder: 1,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 266,
      guild: 'F7C63986-79FD-482C-B6EF-E31416C4EB09',
      level: '5',
      hcode: 'f7c63986',
      name: 'Java',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 268,
      guild: 'D3248E32-F796-41FA-A915-235E8F0AF34D',
      level: '4',
      hcode: 'd3248e32',
      name: 'Object Oriented Languages',
      type: 'FOLDER',
      sortOrder: 1,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 269,
      guild: 'C958FCF9-0910-423F-BB01-77F595BE7495',
      level: '5',
      hcode: 'c958fcf9',
      name: 'C++',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 270,
      guild: 'DC475A62-7AC3-4181-A9CD-4500766F826B',
      level: '4',
      hcode: 'dc475a62',
      name: 'Procedural Languages',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 271,
      guild: 'EBE375DB-6DCC-4061-B2E7-0C9AE99A930D',
      level: '5',
      hcode: 'ebe375db',
      name: 'Pascal',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 272,
      guild: 'F9C69ADE-AA89-43B5-85D8-77AEC024CBED',
      level: '6',
      hcode: 'f9c69ade',
      name: 'Constants & Variables',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 273,
      guild: 'F193B1F0-D8E9-4AA8-8515-97BDA0EA6D40',
      level: '6',
      hcode: 'f193b1f0',
      name: 'Functions & Procedures',
      type: 'FOLDER',
      sortOrder: 1,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 292,
      guild: 'F9C69ADE-AA89-43B5-85D8-77AEC024CBED',
      level: '6',
      hcode: 'f9c69ade',
      name: 'Type & Scope',
      type: 'GoogleDoc',
      sortOrder: 0,
      url: 'https://docs.google.com/document/d/1Id1fkwadt6PjCsJRtTODINEBbPIuqF-dJJ9M15jLW8g/edit?usp=drivesdk',
      sysId: '1Id1fkwadt6PjCsJRtTODINEBbPIuqF-dJJ9M15jLW8g',
      instanceId: '2400',
    },
    {
      id: 293,
      guild: 'F193B1F0-D8E9-4AA8-8515-97BDA0EA6D40',
      level: '6',
      hcode: 'f193b1f0',
      name: 'Return Types',
      type: 'GoogleDoc',
      sortOrder: 1,
      url: 'https://docs.google.com/document/d/1Q4bAdMJggc_los7tEtV8gFFop8DmAwQFfkQuytP_KZA/edit?usp=drivesdk',
      sysId: '1Q4bAdMJggc_los7tEtV8gFFop8DmAwQFfkQuytP_KZA',
      instanceId: '2401',
    },
    {
      id: 294,
      guild: 'F193B1F0-D8E9-4AA8-8515-97BDA0EA6D40',
      level: '6',
      hcode: 'f193b1f0',
      name: 'Decleration',
      type: 'GoogleDoc',
      sortOrder: 0,
      url: 'https://docs.google.com/document/d/1JDaYXNVcB3aPltU84AWN-wc-TcK06-IZ1xANLaiaVcQ/edit?usp=drivesdk',
      sysId: '1JDaYXNVcB3aPltU84AWN-wc-TcK06-IZ1xANLaiaVcQ',
      instanceId: '2402',
    },
    {
      id: 274,
      guild: 'F39A5751-0F91-48DD-810D-752ED5611724',
      level: '5',
      hcode: 'f39a5751',
      name: 'Visual Basic',
      type: 'FOLDER',
      sortOrder: 2,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 275,
      guild: 'A8F34CC9-6D4D-4714-B8D7-C3E8037BD244',
      level: '5',
      hcode: 'a8f34cc9',
      name: 'C',
      type: 'FOLDER',
      sortOrder: 1,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 276,
      guild: 'B419DED8-CA07-4326-9F02-1E3A3A2F0A25',
      level: '6',
      hcode: 'b419ded8',
      name: 'Constants & Variables',
      type: 'FOLDER',
      sortOrder: 0,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 277,
      guild: 'A0685096-2A83-463A-8D2D-5CBDABC4131E',
      level: '6',
      hcode: 'a0685096',
      name: 'Functions',
      type: 'FOLDER',
      sortOrder: 1,
      url: 'NULL',
      sysId: '0',
      instanceId: '0',
      children: [
      ]
    },
    {
      id: 295,
      guild: 'DC475A62-7AC3-4181-A9CD-4500766F826B',
      level: '4',
      hcode: 'dc475a62',
      name: 'Introduction',
      type: 'GoogleDoc',
      sortOrder: 3,
      url: 'https://docs.google.com/document/d/1xS7CTgKG-OD7zzTFxMzySSj9QKzHIFQbjDAkHs4mkoY/edit?usp=drivesdk',
      sysId: '1xS7CTgKG-OD7zzTFxMzySSj9QKzHIFQbjDAkHs4mkoY',
      instanceId: '2403',
    },
  ]
}