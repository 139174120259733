import Vue from 'vue'
import VueRouter from 'vue-router'
import TreeSelect from '../components/TreeSelect.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/tree-select/:curriculumGuid?',
    name: 'TreeSelect',
    component: TreeSelect
  }
]

const router = new VueRouter({
  routes
})

export default router
